.large-card-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-white);
}
.large-card-item {
  position: absolute;
  width: calc(100% - 64px);
  top: calc(50% - 96px);
  right: 32px;
  left: 32px;
  max-width: 100%;
  overflow: hidden;
  height: 202px;
  object-fit: cover;
}
.nylon-jersey-crop {
  position: absolute;
  width: calc(100% - 63px);
  top: calc(50% - 139px);
  left: 32px;
  line-height: 22px;
  display: inline-block;
  height: 59px;
}
.large-card {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.c-1250 {
  position: absolute;
  top: 25px;
  right: 27px;
  display: inline-block;
  color: var(--color-gray-300);
  width: 68px;
  height: 22px;
}
.component-24-1 {
  position: absolute;
  top: 248px;
  left: 35px;
  width: 359px;
  height: 334px;
}
.menus-child,
.menus-item {
  position: absolute;
  height: calc(100% - 2px);
}
.menus-child {
  width: 100%;
  top: 2px;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-mediumblue);
}
.menus-item {
  top: 0;
  bottom: 2px;
  left: calc(50% - 4px);
  background-color: var(--color-white);
  width: 2px;
  opacity: 0.1;
}
.path-415-icon {
  position: absolute;
  top: 100%;
  left: 34.38%;
  width: 0;
  height: 0;
}
.shop-recommended {
  position: absolute;
  top: calc(50% - 7px);
  left: 112px;
  font-weight: 500;
}
.menus-wrapper {
  position: absolute;
  top: 165px;
  left: 35px;
  width: 358px;
  height: 40px;
  color: var(--color-yellowgreen);
}
.o3 {
  color: var(--color-mediumblue);
}
.stylco4 {
  position: absolute;
  top: 6px;
  left: 62px;
  font-size: var(--font-size-101xl);
  font-weight: 100;
  display: inline-block;
  width: 367px;
  height: 151px;
  color: var(--color-gray-100);
}
.component-24-2 {
  position: absolute;
  bottom: 2px;
  left: 35px;
  width: 359px;
  height: 334px;
}
.recommended-feed {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 926px;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-black);
  font-family: var(--font-helvetica-neue);
}
