@import url("https://fonts.googleapis.com/css2?family=Arial:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kailasa:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@100;400;500;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-kailasa: Kailasa;
  --font-arial: Arial;
  --font-helvetica-neue: "Helvetica Neue";

  /* font sizes */
  --font-size-3xs: 10px;
  --font-size-11xl: 30px;
  --font-size-101xl: 120px;
  --font-size-base: 16px;
  --font-size-sm: 14px;

  /* Colors */
  --color-gold-100: #cccc02;
  --color-black: #000;
  --color-gray-100: #0f0f00;
  --color-gray-200: #170012;
  --color-gray-300: #1a0000;
  --color-whitesmoke: #e9eaee;
  --color-dimgray: #707070;
  --color-yellowgreen: #b9db00;
  --color-mediumblue: #4600e0;
  --color-white: #fff;
  --color-gainsboro: #e2e2e2;

  /* Border radiuses */
  --br-9xs: 4px;
}
