.component-26-1-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-gainsboro);
  box-sizing: border-box;
}
.image-21-icon {
  position: absolute;
  height: calc(100% - 34px);
  width: calc(100% - 6px);
  top: 17px;
  right: 3px;
  bottom: 17px;
  left: 3px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.component-26-1,
.component-28-1 {
  position: absolute;
  top: 307px;
  left: 27px;
  width: 111px;
  height: 89px;
}
.component-28-1 {
  top: 419px;
}
.image-25-icon {
  position: absolute;
  width: calc(100% - 9px);
  top: calc(50% - 29.5px);
  right: 5px;
  left: 4px;
  max-width: 100%;
  overflow: hidden;
  height: 57px;
  object-fit: cover;
}
.component-29-1 {
  position: absolute;
  top: 419px;
  left: 159px;
  width: 111px;
  height: 89px;
}
.image-23-icon {
  position: absolute;
  height: calc(100% - 15px);
  width: calc(100% - 19px);
  top: 8px;
  right: 9px;
  bottom: 7px;
  left: 10px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.component-27-1,
.component-30-1 {
  position: absolute;
  top: 307px;
  right: 26px;
  width: 111px;
  height: 89px;
}
.component-30-1 {
  top: 419px;
}
.step-2-of {
  margin: 0;
}
.step-2-of-container {
  position: absolute;
  top: 224px;
  left: 27px;
  display: inline-block;
  width: 201px;
  height: 50px;
}
.rectangle-div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-gray-200);
}
.proceed2 {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 19.5px);
  line-height: 12px;
}
.continue4 {
  position: absolute;
  bottom: 42px;
  left: 51px;
  width: 327px;
  height: 48px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-gold-100);
  font-family: var(--font-arial);
}
.o1 {
  color: var(--color-mediumblue);
}
.stylco2 {
  position: absolute;
  top: 33px;
  left: 62px;
  font-size: var(--font-size-101xl);
  font-weight: 100;
  display: inline-block;
  width: 367px;
  height: 151px;
  color: var(--color-gray-100);
}
.component-26-2 {
  position: absolute;
  top: 307px;
  left: 159px;
  width: 111px;
  height: 89px;
}
.onboarding-step-2 {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 926px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-mediumblue);
  font-family: var(--font-helvetica-neue);
}
