.group-inner,
.group-item {
  position: absolute;
  width: calc(100% + 11.3px);
  right: -7.3px;
  left: -4px;
  max-width: 100%;
  overflow: hidden;
  height: 46.3px;
}
.group-item {
  top: -4px;
}
.group-inner {
  bottom: -7.3px;
}
.line-parent {
  position: relative;
  width: 100%;
  height: 70px;
}
