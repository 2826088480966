.image-12-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 428px;
  height: 570px;
  object-fit: cover;
}
.o {
  color: var(--color-mediumblue);
}
.stylco1 {
  position: absolute;
  bottom: 118px;
  left: 62px;
  font-size: var(--font-size-101xl);
  font-weight: 100;
  display: inline-block;
  width: 367px;
  height: 151px;
}
.landing-screen-option-2-child {
  position: absolute;
  top: 416px;
  left: 155px;
  background-color: var(--color-yellowgreen);
  width: 89px;
  height: 35px;
}
.discover,
.shop,
.youdefineyou {
  position: absolute;
  top: 413px;
  left: 109px;
  font-weight: 100;
  display: inline-block;
  width: 201px;
  height: 50px;
}
.discover,
.shop {
  top: 378px;
  left: 154px;
  color: var(--color-dimgray);
  width: 111px;
  height: 31px;
}
.shop {
  top: 451px;
}
.continue-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-gray-200);
}
.proceed1 {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 19.5px);
  line-height: 12px;
}
.continue1 {
  position: absolute;
  bottom: 44px;
  left: 51px;
  width: 327px;
  height: 48px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-gold-100);
  font-family: var(--font-arial);
}
.landing-screen-option-2 {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 926px;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--color-gray-100);
  font-family: var(--font-helvetica-neue);
}
