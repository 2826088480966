.continue-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-mediumblue);
}
.already-have-an {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 73.5px);
  line-height: 12px;
}
.continue2 {
  position: absolute;
  bottom: 50px;
  left: 45px;
  width: 327px;
  height: 48px;
}
.image-13-icon {
  position: absolute;
  top: -65px;
  left: -265px;
  width: 1001px;
  height: 1001px;
  object-fit: cover;
}
.step-1-of {
  margin: 0;
}
.step-1-of-container {
  position: absolute;
  top: 112px;
  left: 60px;
  font-size: var(--font-size-base);
  font-family: var(--font-helvetica-neue);
  color: var(--color-mediumblue);
  text-align: left;
  display: inline-block;
  width: 201px;
  height: 50px;
}
.take-a-body {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 80.5px);
  line-height: 12px;
}
.group-parent {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.continue3 {
  position: absolute;
  bottom: 50px;
  left: 60px;
  width: 327px;
  height: 48px;
  cursor: pointer;
}
.onboarding-step-1 {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 926px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-white);
  font-family: var(--font-arial);
}
