.component-18-1,
.path-309-icon {
  position: absolute;
  top: 242.92px;
  left: 51.5px;
  width: 67.2px;
  height: 257.6px;
}
.path-309-icon {
  top: 157.13px;
  left: 204.07px;
  width: 4.1px;
  height: 2.7px;
}
.component-20-1 {
  position: absolute;
  top: 239.96px;
  right: 33.7px;
  width: 111.2px;
  height: 260.6px;
}
.component-19-1 {
  position: absolute;
  top: 244.03px;
  left: 164.29px;
  width: 84.9px;
  height: 255.5px;
}
.component-21-1 {
  position: absolute;
  bottom: 85.2px;
  left: 54.53px;
  width: 88.9px;
  height: 254.8px;
}
.component-22-1 {
  position: absolute;
  right: 44.98px;
  bottom: 85.21px;
  width: 88.9px;
  height: 256.5px;
}
.component-17-1,
.continue-child2 {
  position: absolute;
  bottom: 85.3px;
  left: 174.54px;
  width: 61.7px;
  height: 254.7px;
}
.continue-child2 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-gray-200);
  cursor: pointer;
}
.proceed3 {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 19.5px);
  line-height: 12px;
}
.continue6 {
  position: absolute;
  bottom: 9px;
  left: 51px;
  width: 327px;
  height: 48px;
}
.select-body-profile {
  margin: 0;
}
.select-body-profile-container {
  position: absolute;
  top: 170px;
  left: 148px;
  font-size: var(--font-size-base);
  font-family: var(--font-helvetica-neue);
  color: var(--color-mediumblue);
  text-align: left;
  display: inline-block;
  width: 201px;
  height: 50px;
}
.onboarding-step-11 {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 926px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-gold-100);
  font-family: var(--font-arial);
}
