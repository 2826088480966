.step-3-of {
  margin: 0;
}
.step-3-of-container {
  position: absolute;
  top: 171px;
  left: 34px;
  display: inline-block;
  width: 201px;
  height: 50px;
}
.continue-child1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-gray-200);
}
.submit {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 16.5px);
  line-height: 12px;
}
.continue5 {
  position: absolute;
  bottom: 18px;
  left: 51px;
  width: 327px;
  height: 48px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-gold-100);
  font-family: var(--font-arial);
}
.o2 {
  color: var(--color-mediumblue);
}
.stylco3 {
  position: absolute;
  top: 0;
  left: 51px;
  font-size: var(--font-size-101xl);
  font-weight: 100;
  display: inline-block;
  width: 367px;
  height: 151px;
  color: var(--color-gray-100);
}
.image-27-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.component-30-2 {
  position: absolute;
  top: 241px;
  left: 24px;
  width: 182px;
  height: 182px;
  mix-blend-mode: normal;
}
.classic {
  position: absolute;
  top: 220px;
  left: 83px;
  display: inline-block;
  width: 65px;
  height: 21px;
}
.image-28-icon {
  position: absolute;
  top: 241px;
  right: 29px;
  width: 146px;
  height: 182px;
  object-fit: cover;
}
.glam {
  position: absolute;
  top: 220px;
  right: 63px;
  display: inline-block;
  width: 65px;
  height: 21px;
}
.image-29-icon {
  position: absolute;
  top: 482px;
  left: 22px;
  width: 184px;
  height: 245px;
  object-fit: cover;
}
.boho {
  position: absolute;
  top: 442px;
  left: 83px;
  display: inline-block;
  width: 65px;
  height: 21px;
}
.image-30-icon {
  position: absolute;
  top: 482px;
  right: 20px;
  width: 152px;
  height: 245px;
  object-fit: cover;
}
.casual {
  position: absolute;
  top: 442px;
  right: 63px;
  display: inline-block;
  width: 65px;
  height: 21px;
}
.onboarding-step-3 {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 926px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-mediumblue);
  font-family: var(--font-helvetica-neue);
}
