.image-11-icon {
  position: absolute;
  bottom: 105px;
  left: 0;
  width: 870px;
  height: 580px;
  object-fit: cover;
}
.continue-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-gray-200);
}
.proceed {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 19.5px);
  line-height: 12px;
  cursor: pointer;
}
.continue {
  position: absolute;
  bottom: 44px;
  left: 51px;
  width: 327px;
  height: 48px;
}
.landing-screen-option-1-child {
  position: absolute;
  top: 97px;
  left: 2px;
  background-color: var(--color-black);
  width: 158px;
  height: 41px;
}
.stylco {
  position: absolute;
  top: 97px;
  left: 0;
  font-size: 50px;
  display: inline-block;
  font-family: var(--font-kailasa);
  color: #cc0;
  text-align: left;
  width: 184px;
  height: 58px;
}
.landing-screen-option-1 {
  position: relative;
  background-color: #0366b3;
  width: 100%;
  height: 926px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-gold-100);
  font-family: var(--font-arial);
}
